<template>
  <a-card class="card" :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="业务类型">
              <a-select
                placeholder="请选择业务类型"
                style="width: 100%"
                v-model="queryParam.source"
                @change="changeSource"
              >
                <a-select-option :key="2">暂落箱业务</a-select-option>
                <a-select-option :key="3">仓库租赁业务</a-select-option>
                <a-select-option :key="4">仓储行政业务</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan" v-if="queryParam.source !== 4">
            <a-form-item label="委托客户">
              <a-select
                showSearch
                label-in-value
                allowClear
                placeholder="请选择委托客户"
                style="width: 100%"
                :value="customerInfo"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="getDepartmentCustomer"
                @change="changeCustomerInfo"
              >
                <template v-if="fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan" v-else>
            <a-form-item label="付款对象">
              <a-select
                v-model="queryParam['supplier_id']"
                showSearch
                allowClear
                :filter-option="filterOption"
                style="width: 100%"
              >
                <a-select-option v-for="o in payTargetOps" :key="o.value">{{ o.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="业务编号">
              <a-input v-model="queryParam['operate_num']" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan" v-if="queryParam.source === 2">
            <a-form-item label="入场时间">
              <a-range-picker @change="onEnterDateChange"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan" v-if="queryParam.source === 2">
            <a-form-item label="出场时间">
              <a-range-picker @change="onExitDateChange" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan" v-if="queryParam.source !== 4">
            <a-form-item label="开票时间">
              <a-range-picker @change="onInvoiceDateChange"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan" v-if="queryParam.source === 2 || queryParam.source === 3">
            <a-form-item label="费用状态">
              <a-select v-model="queryParam['fee_status']" showSearch allowClear style="width: 100%">
                <a-select-option v-for="o in GLOBAL.finFeeStatusMaps" :key="o.value">{{ o.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-button type="query" icon="search" @click="tableQuery">查询</a-button>
      <a-button type="primary" @click="handleExport">导出</a-button>
    </div>
    <template>
      <s-table
        ref="table"
        size="default"
        rowKey="id"
        :columns="columns"
        dynamicColumns
        :alert="{
          show: true,
          msg: showTotal
        }"
        :data="loadData"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <div slot="boxInfoList" slot-scope="text" v-if="queryParam.source === 2">
          <span v-for="item in text" :key="item.id" style="display:block">
            {{ item.box_name ? `类型：${item.box_name}` : '' }}
            {{ item.box_num ? ` 箱号：${item.box_num}` : '' }}
            {{ item.enter_time ? ` 进场时间：${moment(item.enter_time).format('YYYY/MM/DD')}` : '' }}
            {{ item.exit_time ? ` 出场时间：${moment(item.exit_time).format('YYYY/MM/DD')}` : '' }}
          </span>
        </div>
        <div slot="feeInfoList" slot-scope="fee">
          <div v-for="item in fee" :key="item.id">
            <a-tag :color="feeStatusColor(item.fee_status)" style="margin-bottom:2px;">
              {{ GLOBAL.feeStatusMaps[item.fee_status] ? GLOBAL.feeStatusMaps[item.fee_status].name : '' }}
            </a-tag>
            <span>
              {{ item.fee_name }}：{{ item.amount }}
            </span>
          </div>
        </div>
      </s-table>
    </template>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { getCustomer, getSupplier, downloadExcel } from '@/api/common'
import { filterOption } from '@/utils/util'
import moment from 'moment'
import { getWmsProfitPage, exportWmsWarehouseProfitSheet } from '@/api/wms'
import debounce from 'lodash/debounce';
export default {
  components: {
    STable
  },
  data() {
    this.lastFetchId = 0;
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800);
    return {
      payTargetOps: [],
      customerOps: [],
      customerInfo: undefined,
      fetching: false,
      queryParam: {
        source: 2
      },
      columns: [],
      boxColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: '订单编号',
          dataIndex: 'operate_num'
        },
        {
          title: '委托客户',
          dataIndex: 'customer'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: 200
        },
        {
          title: '箱信息',
          dataIndex: 'box_info_list',
          scopedSlots: { customRender: 'boxInfoList' }
        },
        {
          title: '应收费用',
          dataIndex: 'fee_info_list',
          scopedSlots: { customRender: 'feeInfoList' }
        },
        {
          title: '利润',
          dataIndex: 'profit'
        }
      ],
      rentColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: '订单编号',
          dataIndex: 'operate_num'
        },
        {
          title: '委托客户',
          dataIndex: 'customer'
        },
        {
          title: '合同开始时间',
          dataIndex: 'start_date'
        },
        {
          title: '合同结束时间',
          dataIndex: 'end_date'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: 200
        },
        {
          title: '应收费用',
          dataIndex: 'fee_info_list',
          scopedSlots: { customRender: 'feeInfoList' }
        },
        {
          title: '利润',
          dataIndex: 'profit'
        }
      ],
      officeColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: '订单编号',
          dataIndex: 'operate_num'
        },
        {
          title: '付款对象',
          dataIndex: 'customer'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: 200
        },
        {
          title: '应付费用',
          dataIndex: 'fee_info_list',
          scopedSlots: { customRender: 'feeInfoList' }
        },
        {
          title: '利润',
          dataIndex: 'profit'
        }
      ],
      showTotal: '',
      loadData: parameter => {
        return getWmsProfitPage(Object.assign(parameter, this.queryParam)).then(res => {
          this.showTotal = '票数合计:' + res.total + ';利润金额合计:' + res.total_row.profit
          return res
        })
      }
    }
  },
  created() {
    getSupplier({ department: 1 }).then(res => {
      this.payTargetOps = res
    })
    this.columns = this.boxColumns
  },
  methods: {
    filterOption,
    moment,
    tableQuery() {
      this.$refs.table.refresh(true)
    },
    changeSource(value) {
      if (value === 2) {
        this.columns = this.boxColumns
      } else if (value === 3) {
        this.columns = this.rentColumns
      } else if (value === 4) {
        this.columns = this.officeColumns
      }
      this.$refs.table.refresh(true)
    },
    onEnterDateChange(date) {
      if (date[0]) {
        this.queryParam['enter_start_date'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['enter_end_date'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParam['enter_start_date'] = null
        this.queryParam['enter_end_date'] = null
      }
    },
    onExitDateChange(date, dateString) {
      if (date[0]) {
        this.queryParam['exit_start_date'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['exit_end_date'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParam['exit_start_date'] = null
        this.queryParam['exit_end_date'] = null
      }
    },
    onInvoiceDateChange(date) {
      if (date[0]) {
        this.queryParam['invoice_start_date'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['invoice_end_date'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParam['invoice_start_date'] = null
        this.queryParam['invoice_end_date'] = null
      }
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.customerOps = [];
      this.fetching = true;
      getCustomer({
        department: 1,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.customerOps = res
        this.fetching = false;
      })
    },
    changeCustomerInfo(value) {
      this.customerInfo = value
      this.queryParam['customer_id'] = value.key
      this.customerOps = []
      this.fetching = false
    },
    feeStatusColor(value) {
      switch (value) {
        case 1:
          return '#faad14'
        case 2:
          return '#1890ff'
        case 3:
          return '#4cd964'
        case 4:
          return '#ff4d4f'
        default:
          return '#4cd964'
      }
    },
    handleExport() {
      exportWmsWarehouseProfitSheet(this.queryParam).then(res => {
        if (res !== null) {
          const fileInfo = {
            fileName: res
          }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      })
    }
  }
}
</script>
